.banner {
  padding: 8px 16px;
  display: flex;
  justify-content: space-between;
  background-color: var(--chakra-colors-gray-50);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.text-item {
  background-color: antiquewhite;
  border-radius: 4px;
  padding: 4px;
  margin: 8px 4px;
  display: inline-block;
}

.selected {
  background-color: darkorange;
  color: white;
}

.button-row {
  display: flex;
  justify-content: center;
}

.add-button {
  background-color: cornflowerblue;
  color: white;
  font-weight: bold;
  padding: 6px;
  border-radius: 4px;
}

.def-item {
  padding: 8px;
  margin: 4px;
  background-color: rgb(253, 246, 238);
  display: flex;
}

.def-area {
  flex: 1;
}

.button-area {
  display: flex;
  align-items: center;
  font-size: 40px;
  color: blue;
}

.def-headword {
  display: inline-block;
  margin-right: 8px;
  font-weight: bold;
}

.def-pos {
  display: inline-block;
  margin-right: 4px;
}
